import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../core/api/base.api';
import { ICurrency } from '../../core/interfaces/ICurrency';

class StatusApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/supplier/status';
  private accountPath = '/api/account';

  async getStatus(): Promise<AxiosResponse<ISupplierStatus>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}`);
  }

  async requestMarketplaceApproval() {
    return axiosInstance.post(`${this.backend}${this.accountPath}/request-marketplace-approval`);
  }
}

export const statusApi = new StatusApi();

export enum MARKETPLACE_APPROVAL_STATUS {
  NOT_ISSUED = 'not issued',
  PENDING = 'pending',
  APPROVED = 'approved',
}

export interface ISupplierStatus {
  connected: boolean;
  cancellationRequests: number;
  updatingPriceMappingPreference: boolean;
  marketplaceApprovalStatus: MARKETPLACE_APPROVAL_STATUS;
  currency: ICurrency;
  transactionFee: number;
  payoutPendingPayoutsCount: number;
  updatedApi: boolean;
  permissionUrl?: string;
  connectedPayment: boolean;
}
