import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';

type NoPaymentBannerProps = {
  connectedPayment: boolean;
};

export const NoPaymentMethodBanner = ({ connectedPayment }: NoPaymentBannerProps) => {
  if (connectedPayment) return null;
  return (
    <div className="banner">
      <AppBanner status="critical" title="No payment method">
        <p>
          Attention: Your payouts are on hold because you have not assigned a payout method. Choose
          a method and upload a w9 file to your Crowdship{' '}
          <AppLink url="/settings?tab=payouts">Payout Settings</AppLink> to reinstate automated
          transfers.
        </p>
      </AppBanner>
    </div>
  );
};
