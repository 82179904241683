import { Provider as AppBridgeProvider, TitleBar } from '@shopify/app-bridge-react';
import jwt from 'jsonwebtoken';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
// import { adminApi } from '../core/api/admin.api';
import { setAxiosAuthorization } from '../core/api/base.api';
import { AppFooterHelp } from '../core/components/footer-help/footer-help';
import { AppLink } from '../core/components/link/link';
import { AppFrame } from '../core/components/structure/frame/frame';
import { ViewAsClientNotification } from '../core/components/view-as-client-notification/view-as-client-notification';
import { getTitleBar, ITitleBarProps } from '../core/helpers/get-title-bar.helper';
import { LoadingLayout } from '../core/layouts/loading-layout/loading-layout';
// import { setViewAsClientSettingAction } from '../core/redux/modules/admin/admin.actions';
import {
  setAuthenticatedAction,
  setHostAction,
  setRolesAction,
  setStoreHostAction,
  setStoreActivityAction,
} from '../core/redux/modules/auth/auth.actions';
// import { ACCOUNT_ROLE } from '../core/redux/modules/auth/auth.reducer';
import {
  getHostSelector,
  isAuthenticatedSelector,
} from '../core/redux/modules/auth/auth.selectors';
import './App.scss';
import { ApiUpdateLayout } from './components/layouts/api-update-layout/api-update-layout';
import { CallbackLayout } from './components/layouts/callback-layout/callback-layout';
import { getStatusAction } from './redux/modules/status/status.actions';
import { Router } from './routes/Router';
import { AppBanners } from './components/banners/app-banners/app-banners';

type IAuthenticationResponse =
  | { accessToken: string }
  | { archived: string }
  | { installationUrl: Location };

export function App() {
  console.info('Trigger deployment');
  const dispatch = useDispatch();

  const { boot } = useIntercom();

  const { pathname, search: query } = useLocation();
  const isCallbackPage = pathname.includes('callback') || pathname.includes('api-update');

  const authenticated = useSelector(isAuthenticatedSelector);
  const host = useSelector(getHostSelector);

  const [archivedMessage, setArchivedMessage] = useState<string | undefined>(undefined);
  const [titleBar, setTitleBar] = useState<ITitleBarProps>({ breadcrumbs: [], title: '' });
  const [headersSet, setHeadersSet] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      const BACK_END_URL = process.env.REACT_APP_BACK_END_URL;
      const data: IAuthenticationResponse = (
        await axios.get(`${BACK_END_URL}/api/supplier/shopify${query}`)
      ).data;

      if ('accessToken' in data && data.accessToken) {
        const parsedQuery = new URLSearchParams(query);
        const host = parsedQuery.get('host') as string;
        const storeHost = parsedQuery.get('shop') as string;
        const tokenData: any = jwt.decode(data.accessToken);
        if (!tokenData) throw new Error('Cannot parse token');
        boot({
          userId: tokenData.id,
          name: tokenData.name,
          email: tokenData.email,
          phone: tokenData.phone,
          company: {
            companyId: tokenData.customerId,
            name: tokenData.name,
            website: storeHost,
          },
        });
        setAxiosAuthorization(data.accessToken);
        dispatch(setAuthenticatedAction(data.accessToken));
        dispatch(setHostAction(host));
        dispatch(setStoreHostAction(storeHost));
        dispatch(setRolesAction(tokenData.roles));
        dispatch(setStoreActivityAction(tokenData.inactiveStore));
        // Disable admin tools for legacy
        // if (tokenData.roles.includes(ACCOUNT_ROLE.ADMIN)) {
        //   const { data } = await adminApi.getViewAsClientSetting();
        //   dispatch(setViewAsClientSettingAction(data.viewAsClient));
        //   setAxiosViewAsClient(data.viewAsClient?._id);
        // }
        setHeadersSet(true);
        dispatch(getStatusAction());
      } else if ('archived' in data) {
        setArchivedMessage(data.archived);
      } else if ('installationUrl' in data) {
        window.location = data.installationUrl;
      }
    };

    if (query && query.length && !isCallbackPage && !authenticated) {
      checkAuthentication();
    } else if (isCallbackPage) {
      setHeadersSet(true);
    }
  }, [dispatch, isCallbackPage, query, authenticated, boot]);

  useEffect(() => {
    setTitleBar(getTitleBar(pathname));
  }, [pathname]);

  if (authenticated && host && headersSet) {
    const apiKey = process.env.REACT_APP_SHOPIFY_API_KEY as string;
    const config = {
      apiKey,
      host,
    };
    return (
      <AppBridgeProvider config={config}>
        <AppProvider
          i18n={enTranslations}
          linkComponent={({ url, ref, ...rest }) => <Link to={url} {...rest} />}
        >
          <AppFrame>
            <TitleBar title={titleBar.title} breadcrumbs={titleBar.breadcrumbs} />
            <ViewAsClientNotification />
            <AppBanners />
            <Router />
            <AppFooterHelp>
              Learn more about{' '}
              <AppLink onClick={() => window.open('https://help.crowdship.io/', '_blank')} external>
                selling on Crowdship
              </AppLink>{' '}
              at the Crowdship Help Center.
            </AppFooterHelp>
          </AppFrame>
        </AppProvider>
      </AppBridgeProvider>
    );
  }

  if (archivedMessage) {
    return <div>{archivedMessage}</div>;
  }

  // if user is not authorized then he has limited routing
  // with only two routes:
  // 1) home - limited, with just showing message
  // 2) oauth callback - for install process
  return (
    <>
      <Switch>
        <Route exact path="/" component={LoadingLayout} />
        <Route exact path="/oauth/callback" component={CallbackLayout} />
        <Route exact path="/api-update" component={ApiUpdateLayout} />
      </Switch>
    </>
  );
}
